import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Navbar from "../Navbar";
import { Switch } from "@headlessui/react";
import { DocumentTextIcon, LinkIcon } from "@heroicons/react/solid";
import amplitude from "amplitude-js";

const PipedriveIntegration = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [pipedriveConnected, setPipedriveConnected] = useState(false);
  const [connectionStatus, setConnectionStatus] = useState("disconnected");
  const [settings, setSettings] = useState({
    addMeetingNotes: true,
    createCompanyCollections: true,
  });
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    checkPipedriveConnection();
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("code");
    if (code) {
      handlePipedriveCallback(code);
    }
  }, [location]);

  useEffect(() => {
    if (user) {
      setLoading(false);
      amplitude
        .getInstance()
        .logEvent("Integrations pipedrive integration visited");
    }
  }, [user, pipedriveConnected, connectionStatus]);

  const checkPipedriveConnection = async () => {
    try {
      const response = await fetch("https://backend.scribbl.co/pipedrive/status", {
        method: "GET",
        credentials: "include",
      });
      if (response.ok) {
        const data = await response.json();
        setPipedriveConnected(data.connected);
        setConnectionStatus(data.connected ? "connected" : "disconnected");
        if (data.connected) {
          setSettings({
            addMeetingNotes: data.addMeetingNotes,
            createCompanyCollections: data.createCompanyCollections,
          });
        }
      }
    } catch (error) {
      console.error("Error checking Pipedrive connection:", error);
      setConnectionStatus("error");
    }
  };

  const handlePipedriveCallback = async (code) => {
    try {
      setConnectionStatus("connecting");
      const response = await fetch(
        `https://backend.scribbl.co/pipedrive/callback?code=${code}`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();
        if (data.status === "success") {
          await checkPipedriveConnection();
          amplitude.getInstance().logEvent("Integrations pipedrive connected");
          navigate("/integrations/pipedrive", { replace: true });
        } else {
          throw new Error("Failed to exchange code for token");
        }
      } else {
        throw new Error("Failed to exchange code for token");
      }
    } catch (error) {
      console.error("Error handling Pipedrive callback:", error);
      setConnectionStatus("error");
    }
  };

  const handleConnectPipedrive = async () => {
    try {
      setConnectionStatus("connecting");
      const response = await fetch("https://backend.scribbl.co/pipedrive/connect", {
        method: "GET",
        credentials: "include",
      });
      if (response.ok) {
        const data = await response.json();
        window.location.href = data.authUrl;
      }
    } catch (error) {
      console.error("Error connecting to Pipedrive:", error);
      setConnectionStatus("error");
    }
  };

  const handleDisconnectPipedrive = async () => {
    try {
      setConnectionStatus("disconnecting");
      const response = await fetch(
        "https://backend.scribbl.co/pipedrive/disconnect",
        {
          method: "POST",
          credentials: "include",
        }
      );
      if (response.ok) {
        setPipedriveConnected(false);
        setConnectionStatus("disconnected");
        setSettings({
          addMeetingNotes: false,
          createCompanyCollections: false,
        });
      } else {
        throw new Error("Failed to disconnect from Pipedrive");
      }
    } catch (error) {
      console.error("Error disconnecting from Pipedrive:", error);
      setConnectionStatus("error");
    }
  };

  const handleSettingChange = async (setting, value) => {
    const newSettings = { ...settings, [setting]: value };
    setSettings(newSettings);

    try {
      const response = await fetch("https://backend.scribbl.co/pipedrive/settings", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newSettings),
      });

      if (!response.ok) {
        throw new Error("Failed to update Pipedrive settings");
      }
    } catch (error) {
      console.error("Error updating Pipedrive settings:", error);
      setSettings((prevSettings) => ({
        ...prevSettings,
        [setting]: !value,
      }));
    }
  };

  const renderConnectionStatus = () => {
    switch (connectionStatus) {
      case "connected":
        return (
          <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-green-100 text-green-800">
            <span className="w-2 h-2 mr-2 bg-green-400 rounded-full"></span>
            Connected
          </span>
        );
      case "disconnected":
        return (
          <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-gray-100 text-gray-800">
            <span className="w-2 h-2 mr-2 bg-gray-400 rounded-full"></span>
            Disconnected
          </span>
        );
      case "connecting":
        return (
          <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800">
            <svg
              className="animate-spin -ml-1 mr-2 h-4 w-4 text-blue-600"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            Connecting
          </span>
        );
      case "disconnecting":
        return (
          <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-yellow-100 text-yellow-800">
            <svg
              className="animate-spin -ml-1 mr-2 h-4 w-4 text-yellow-600"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            Disconnecting
          </span>
        );
      case "error":
        return (
          <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-red-100 text-red-800">
            <span className="w-2 h-2 mr-2 bg-red-400 rounded-full"></span>
            Error
          </span>
        );
      default:
        return null;
    }
  };

  const SettingToggle = ({ setting, label, description }) => (
    <div className="flex items-center justify-between py-4 border-b border-gray-200 last:border-b-0">
      <div className="pr-4">
        <h4 className="text-sm font-medium text-gray-900">{label}</h4>
        <p className="text-sm text-gray-500 mt-1">{description}</p>
      </div>
      <Switch
        checked={settings[setting]}
        onChange={(value) => handleSettingChange(setting, value)}
        className={`${
          settings[setting] ? "bg-brand-green-darker2" : "bg-gray-200"
        } relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-green-darker2`}
      >
        <span className="sr-only">Enable setting</span>
        <span
          className={`${
            settings[setting] ? "translate-x-5" : "translate-x-0"
          } pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
        >
          <span
            className={`${
              settings[setting]
                ? "opacity-0 ease-out duration-100"
                : "opacity-100 ease-in duration-200"
            } absolute inset-0 h-full w-full flex items-center justify-center transition-opacity`}
            aria-hidden="true"
          >
            <svg
              className="h-3 w-3 text-gray-400"
              fill="none"
              viewBox="0 0 12 12"
            >
              <path
                d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <span
            className={`${
              settings[setting]
                ? "opacity-100 ease-in duration-200"
                : "opacity-0 ease-out duration-100"
            } absolute inset-0 h-full w-full flex items-center justify-center transition-opacity`}
            aria-hidden="true"
          >
            <svg
              className="h-3 w-3 text-brand-green-darker2"
              fill="currentColor"
              viewBox="0 0 12 12"
            >
              <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
            </svg>
          </span>
        </span>
      </Switch>
    </div>
  );

  return (
    <Navbar setUser={setUser} loading={loading}>
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex items-center justify-between mb-8">
          <h1 className="text-3xl font-bold text-gray-900">
            Pipedrive Integration
          </h1>
          {/* Add Pipedrive logo here */}
          <svg
            className="w-48 h-16"
            width="1000"
            height="274"
            viewBox="0 0 1000 274"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_4_435)">
              <path
                d="M191.44 72.37C201.398 72.37 209.47 64.2977 209.47 54.34C209.47 44.3823 201.398 36.31 191.44 36.31C181.482 36.31 173.41 44.3823 173.41 54.34C173.41 64.2977 181.482 72.37 191.44 72.37Z"
                fill="#017737"
              />
              <path
                d="M207.63 81.4H175.26V192.46H207.63V81.4Z"
                fill="#017737"
              />
              <path
                d="M712.37 72.37C722.328 72.37 730.4 64.2977 730.4 54.34C730.4 44.3823 722.328 36.31 712.37 36.31C702.412 36.31 694.34 44.3823 694.34 54.34C694.34 64.2977 702.412 72.37 712.37 72.37Z"
                fill="#017737"
              />
              <path
                d="M728.56 81.4H696.19V192.46H728.56V81.4Z"
                fill="#017737"
              />
              <path
                d="M963.96 133.27C963.96 101.57 938.69 78.96 908.32 78.96C874.18 78.96 849.35 102.68 849.35 136.82C849.35 170.52 872.63 194.68 908.32 194.68C938.69 194.68 958.86 175.84 962.63 155H931.37C927.82 164.09 917.4 169.19 907.65 169.19C894.57 168.97 884.82 161.21 881.94 144.36H963.74L963.96 133.27ZM882.6 124.18C886.15 107.78 898.34 103.79 908.31 103.79C918.51 103.79 930.48 108.89 932.03 124.18H882.6Z"
                fill="#017737"
              />
              <path
                d="M825.41 81.4H858.66L813.43 192.46H779.3L734.07 81.4H768.88L797.03 154.99L825.41 81.4Z"
                fill="#017737"
              />
              <path
                d="M647.4 99.13C654.94 87.38 666.91 79.84 680.65 79.84C683.09 79.84 686.19 80.06 688.19 80.73V111.1C685.53 110.66 682.65 110.44 679.99 110.44C659.15 110.44 648.29 125.74 648.29 147.68V192.46H616.15V110.66C616.15 108.89 615.48 108.44 613.49 108.44H602.4V81.4H630.33C641.64 81.4 647.4 86.72 647.4 96.7V99.13Z"
                fill="#017737"
              />
              <path
                d="M596.63 165.41C594.86 165.41 594.41 164.97 594.41 163.19V52.36C594.41 41.28 588.65 36.18 576.9 36.18H548.75V63H559.17C561.16 63 561.83 63.66 561.83 65.44V91.6C557.62 86.06 545.87 78.96 530.13 78.96C498.87 78.96 476.04 102.68 476.04 136.82C476.04 171.62 497.99 194.68 529.24 194.68C546.75 194.68 559.17 185.81 563.82 178.5C564.26 183.38 567.59 192.47 579.56 192.47H608.16V165.43H596.63V165.41ZM535.89 168.07C520.37 168.07 509.07 156.1 509.07 136.81C509.07 118.41 520.6 106 536.11 106C553.62 106 562.49 121.52 562.49 136.59C562.49 160.09 548.31 168.07 535.89 168.07Z"
                fill="#017737"
              />
              <path
                d="M468.94 133.27C468.94 101.57 443.67 78.96 413.3 78.96C379.16 78.96 354.33 102.68 354.33 136.82C354.33 170.52 377.61 194.68 413.3 194.68C443.67 194.68 463.84 175.84 467.61 155H436.35C432.8 164.09 422.38 169.19 412.63 169.19C399.55 168.97 389.8 161.21 386.92 144.36H468.72L468.94 133.27ZM387.59 124.18C391.14 107.78 403.33 103.79 413.3 103.79C423.5 103.79 435.47 108.89 437.02 124.18H387.59Z"
                fill="#017737"
              />
              <path
                d="M294.93 78.96C276.75 78.96 263.89 88.49 259.02 96.69C258.13 92.03 255.03 81.39 242.62 81.39H215.58V108.44H226.66C228.65 108.44 229.1 108.88 229.1 110.66V237.9H261.47V188.69L261.25 180.71C265.9 188.47 278.32 194.68 293.39 194.68C325.09 194.68 347.26 171.18 347.26 136.82C347.24 102.23 326.18 78.96 294.93 78.96ZM286.72 168.07C269.21 168.07 260.78 152.33 260.78 137.26C260.78 114.21 274.75 106 287.16 106C302.23 106 314.2 118.64 314.2 137.03C314.21 158.76 299.58 168.07 286.72 168.07Z"
                fill="#017737"
              />
              <path
                d="M167.68 136.82C167.68 171.18 145.51 194.68 113.81 194.68C98.74 194.68 86.32 188.47 81.67 180.71L81.89 188.69V237.9H49.53V110.66C49.53 108.89 49.09 108.44 47.09 108.44H36V81.4H63.05C75.46 81.4 78.57 92.04 79.45 96.7C84.33 88.5 97.18 78.97 115.36 78.97C146.62 78.96 167.68 102.23 167.68 136.82ZM134.65 137.04C134.65 118.64 122.68 106.01 107.6 106.01C95.19 106.01 81.22 114.21 81.22 137.27C81.22 152.34 89.64 168.08 107.16 168.08C120.02 168.07 134.65 158.76 134.65 137.04Z"
                fill="#017737"
              />
            </g>
            <defs>
              <clipPath id="clip0_4_435">
                <rect width="1000" height="274" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>

        <div className="bg-white shadow sm:rounded-lg mb-8 overflow-hidden">
          <div className="px-4 py-5 sm:p-6">
            <div className="flex items-center justify-between mb-4">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Connection Status
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  Connect Scribbl with your Pipedrive account to sync meeting
                  notes.
                </p>
              </div>
              {renderConnectionStatus()}
            </div>
            <div className="mt-5">
              <div className="mt-5">
                {!pipedriveConnected && user && user.orgID ? (
                  <button
                    type="button"
                    onClick={handleConnectPipedrive}
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-brand-green hover:bg-brand-green-darker2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-green-darker2"
                  >
                    <LinkIcon className="mr-2 h-5 w-5" />
                    Connect to Pipedrive
                  </button>
                ) : (
                  user &&
                  user.orgID && (
                    <button
                      type="button"
                      onClick={handleDisconnectPipedrive}
                      className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-green-darker2"
                    >
                      Disconnect from Pipedrive
                    </button>
                  )
                )}
              </div>
            </div>
          </div>

          {pipedriveConnected && (
            <div className="bg-white shadow sm:rounded-lg mb-8 overflow-hidden">
              <div className="px-4 py-5 sm:p-6">
                <h2 className="text-lg leading-6 font-medium text-gray-900 mb-4 flex items-center">
                  <DocumentTextIcon className="h-6 w-6 text-blue-500 mr-2" />
                  Meeting Notes Sync
                </h2>
                <p className="text-sm text-gray-500 mb-6">
                  Configure how Scribbl syncs your meeting notes with Pipedrive.
                </p>
                <div className="space-y-4">
                  <SettingToggle
                    setting="addMeetingNotes"
                    label="Add meeting notes to Pipedrive"
                    description="Add Scribbl's AI meeting notes to Pipedrive as activities. Automatically links to relevant contacts, organizations, and deals, enriching your Pipedrive data with AI-powered meeting intelligence."
                  />
                  <SettingToggle
                    setting="createCompanyCollections"
                    label="Create collections for Pipedrive organizations"
                    description="Automatically organize meetings into collections based on Pipedrive organization associations"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Navbar>
  );
};

export default PipedriveIntegration;
