import React, { useState, useEffect, useCallback, useRef } from "react";
import Navbar from "../Navbar";
import { useParams, useLocation } from "react-router-dom";
import AIChat from "../AIChat";
import ShareModal from "./ShareModal"; // Adjust the import path as necessary
import LeaveCollectionModal from "./LeaveCollectionModal";
import CollectionIntegrationsTab from "./CollectionIntegrationsTab";
import CollectionSettingsTab from "./CollectionSettingsTab";
import moment from "moment";

import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import {
  ThumbUpIcon,
  ThumbDownIcon,
  ShareIcon,
  CheckIcon,
  ExclamationCircleIcon,
  PlusIcon,
} from "@heroicons/react/outline";

import amplitude from "amplitude-js";
import CollectionSettingsViewOnly from "./CollectionSettingsTabViewOnly";
import AddMeetingsToCollectionModal from "./AddMeetingsToCollectionModal";

const Collection = () => {
  const [collection, setCollection] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState("Collection");

  const [userEmail, setUserEmail] = useState(null);
  const [user, setUser] = useState(null);
  const [userAccessType, setUserAccessType] = useState(null);

  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);
  const [isAddMeetingsModalOpen, setIsAddMeetingsModalOpen] = useState(false);

  const [showPreview, setShowPreview] = useState(false);

  const { collectionUUID } = useParams();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    setShowPreview(queryParams.get("preview") === "true");
  }, [location]);

  const headerRef = useRef(null);

  const tabs = [
    { name: "Collection", accessLevel: 3 },
    { name: "AI Chat", accessLevel: 3 },
    { name: "Settings", accessLevel: 2 },
    { name: "Integrations", accessLevel: 1 },
  ];

  const renderTabs = () => {
    return tabs
      .filter((tab) =>
        userAccessType
          ? userAccessType <= tab.accessLevel
          : tab.accessLevel === 3 || tab.name === "Settings"
      )
      .map((tab) => (
        <div
          key={tab.name}
          className={`pb-3 cursor-pointer ${
            selectedTab === tab.name
              ? "text-brand-green border-b-2 border-brand-green"
              : "text-gray-600 hover:text-brand-green"
          }`}
          onClick={() => setSelectedTab(tab.name)}
        >
          {tab.name}
        </div>
      ));
  };

  const renderContent = () => {
    switch (selectedTab) {
      case "Collection":
        return collection &&
          collection.enhancedRecordings &&
          collection.enhancedRecordings.length > 0 ? (
          <CollectionContent />
        ) : (
          <EmptyCollectionState />
        );
      case "AI Chat":
        return <ChatContent />;
      case "Settings":
        return userAccessType === 1 ? (
          <CollectionSettingsTab
            collection={collection}
            setCollection={setCollection}
          />
        ) : (
          <CollectionSettingsViewOnly collection={collection} />
        );
      case "Integrations":
        return <CollectionIntegrationsTab collection={collection} />;
      default:
        return <CollectionContent />;
    }
  };

  useEffect(() => {
    if (collection) {
      const queryParams = new URLSearchParams(location.search);
      const tabParam = queryParams.get("tab");
      if (
        tabParam &&
        ["Collection", "AI Chat", "Settings", "Integrations"].includes(tabParam)
      ) {
        setSelectedTab(tabParam);
      }
    }
  }, [location, collection]);

  useEffect(() => {
    if (collection && user && user.email) {
      setLoading(false);
      amplitude.getInstance().logEvent("Viewed Individual Collection Page");
    }
  }, [collection, user]);

  const handleLeaveCollection = async () => {
    try {
      const response = await fetch(
        `https://backend.scribbl.co/collections/${collectionUUID}/leave`,
        {
          method: "POST",
          credentials: "include",
        }
      );

      if (response.ok) {
        // Redirect to the collections page
        window.location.href = "/collections";
      } else {
        console.error("Failed to leave collection");
        // Handle error (e.g., show an error message to the user)
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle error (e.g., show an error message to the user)
    } finally {
      setIsLeaveModalOpen(false);
    }
  };

  const fetchCollectionDetails = async () => {
    try {
      const response = await fetch(
        `https://backend.scribbl.co/collections/${collectionUUID}/meetings`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        let tiles = null;
        try {
          tiles = JSON.parse(data.tiles);
        } catch (error) {
          console.log("Couldn't parse tile data", error);
        }
        setCollection({ ...data, tiles: tiles });

        const userAccess = data.usersAccess.find(
          (access) => access.userID === user.ID
        );

        setUserAccessType(userAccess ? userAccess.accessType : null);
      } else {
        console.error("Error fetching collection details");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchCollectionDetails();
  }, [collectionUUID, user]);

  const EmptyCollectionState = () => {
    return (
      <div className="flex flex-col items-center justify-center h-full bg-gray-50 p-8 rounded-lg shadow-inner">
        <svg
          width="200"
          height="200"
          viewBox="0 0 200 200"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M40 60C40 53.3726 45.3726 48 52 48H88L100 60H148C154.627 60 160 65.3726 160 72V140C160 146.627 154.627 152 148 152H52C45.3726 152 40 146.627 40 140V60Z"
            fill="#21A0A0"
          />
          <path
            d="M40 140V72H160V140C160 146.627 154.627 152 148 152H52C45.3726 152 40 146.627 40 140Z"
            fill="#37A9A9"
          />
          <path
            d="M88 48L100 60H148C154.627 60 160 65.3726 160 72V80H40V60C40 53.3726 45.3726 48 52 48H88Z"
            fill="#1C8787"
          />
        </svg>
        <h2 className="text-2xl font-bold text-gray-800 mb-4">
          Your Collection Awaits
        </h2>
        <p className="text-gray-600 text mb-8 max-w-2xl">
          Collections are centralized meeting hubs that automatically gather
          related conversations based on your rules. They extract key insights
          and share to your favorite tools like Google Drive and Slack, helping
          you track progress and keep your team aligned by organizing scattered
          meetings into one cohesive space.
        </p>
        <div className="flex space-x-6">
          <button
            onClick={() => setIsAddMeetingsModalOpen(true)}
            className="bg-brand-turq hover:bg-brand-green-lighter1 text-white font-bold py-3 px-6 rounded-lg transition duration-150 ease-in-out flex items-center"
          >
            <svg
              className="w-5 h-5 mr-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
              />
            </svg>
            Add Meetings Manually
          </button>
          <button
            onClick={() => setSelectedTab("Settings")}
            className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-3 px-6 border border-gray-400 rounded-lg shadow transition duration-150 ease-in-out flex items-center"
          >
            <svg
              className="w-5 h-5 mr-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
            Setup Automatic Rules
          </button>
        </div>
      </div>
    );
  };

  const CollectionContent = () => {
    const handleFeedbackClick = (type) => {
      setFeedbackGiven(type);
    };
    const timelineData = collection?.tiles?.timelineTile;
    const aiTiles = collection?.tiles?.aiTiles;
    const aiSummary = collection?.tiles?.aiSummary;
    const salesDeailInsights = collection?.tiles?.salesDealInsight;

    const [feedbackGiven, setFeedbackGiven] = useState(false);
    const [feedbackText, setFeedbackText] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [feedbackSubmitted, setFeedbackSubmitted] = useState(false); // New state

    console.log("collection", collection);

    const healthColor = {
      Green: "text-green-600",
      Yellow: "text-yellow-600",
      Red: "text-red-600",
    };

    return (
      <div className="p-4">
        <div className="grid grid-cols-3 gap-4">
          {/* AI summary Section */}
          {showPreview && aiSummary && (
            <div className="col-span-3 bg-white rounded-lg shadow p-4">
              <div className="bg-white rounded-lg shadow p-4">
                <Markdown
                  remarkPlugins={[remarkGfm]}
                  className="markdown-style"
                >
                  {aiSummary}
                </Markdown>
              </div>
            </div>
          )}
          {/* Timeline Tile */}
          <div className="col-span-3">
            <div className="bg-white rounded-lg shadow p-4">
              <div className="bg-white rounded-lg shadow p-4">
                <h2 className="font-semibold text-lg text-gray-800 mb-4">
                  Timeline of Meetings
                </h2>
                <div className="flex">
                  {/* Continuous Line Section */}
                  <div className="w-0.5 bg-gray-300 my-2 mx-4"></div>

                  {/* Timeline Section */}
                  <div className="flex-grow">
                    {timelineData && timelineData.length > 0 ? (
                      timelineData?.map((item, index) => (
                        <div
                          key={index}
                          className="grid grid-cols-[100px_auto] items-center mb-4 gap-4"
                        >
                          {/* Date */}
                          <p className="text-sm font-medium text-gray-500 justify-self-end">
                            {item.date}
                          </p>

                          {/* Title/Description */}
                          <div>
                            <h3 className="text-md font-semibold text-gray-800">
                              {item.title}
                            </h3>
                            <p className="text-sm text-gray-600">
                              {item.description}
                            </p>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="text-gray-600">
                        No timeline data available.
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {showPreview && salesDeailInsights && (
            <div className="col-span-3 bg-white rounded-lg shadow p-6">
              <h2 className="font-semibold text-xl text-gray-800 mb-4">
                Sales Deal Insight
              </h2>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p className="font-medium">
                    Deal Stage:{" "}
                    <span className="font-normal">
                      {salesDeailInsights.dealStage}
                    </span>
                  </p>
                  <p className="font-medium">
                    Deal Value:{" "}
                    <span className="font-normal">
                      {salesDeailInsights.dealValue}
                    </span>
                  </p>
                  <p className="font-medium">
                    Close Probability:{" "}
                    <span className="font-normal">
                      {salesDeailInsights.closeProbability}%
                    </span>
                  </p>
                  <p className="font-medium">
                    Expected Close:{" "}
                    <span className="font-normal">
                      {salesDeailInsights.expectedCloseDate}
                    </span>
                  </p>
                  <p className="font-medium">
                    Deal Health:{" "}
                    <span
                      className={`font-semibold ${
                        healthColor[salesDeailInsights.dealHealth]
                      }`}
                    >
                      {salesDeailInsights.dealHealth}
                    </span>
                  </p>
                </div>
                <div>
                  <p className="font-medium">Key Decision Makers:</p>
                  <ul className="list-disc pl-5 mb-2">
                    {salesDeailInsights.keyDecisionMakers?.map((maker, i) => (
                      <li key={i}>{maker}</li>
                    ))}
                  </ul>
                  <p className="font-medium">Competitors:</p>
                  <ul className="list-disc pl-5 mb-2">
                    {salesDeailInsights.competitors?.map((competitor, i) => (
                      <li key={i}>{competitor}</li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="mt-4">
                <p className="font-medium">
                  Key Blocker:{" "}
                  <span className="font-normal">
                    {salesDeailInsights.keyBlocker}
                  </span>
                </p>
                <p className="font-medium mt-2">Critical Risks:</p>
                <ul className="list-disc pl-5">
                  {salesDeailInsights.criticalRisks?.map((risk, i) => (
                    <li key={i}>{risk}</li>
                  ))}
                </ul>
              </div>
            </div>
          )}

          {/* AI Tiles Section */}
          {showPreview && aiTiles && aiTiles.length > 0 && (
            <div className="md:col-span-3 grid grid-cols-2 gap-4">
              {aiTiles?.map((tile, index) => (
                <div key={index} className="bg-white rounded-lg shadow p-4">
                  <h3 className="font-semibold text-lg text-gray-800 mb-2">
                    {tile.tileTitle}
                  </h3>
                  <Markdown
                    remarkPlugins={[remarkGfm]}
                    className="markdown-style"
                  >
                    {tile.tileContent}
                  </Markdown>
                </div>
              ))}
            </div>
          )}
        </div>
        {/* Feedback tile */}
        {feedbackSubmitted ? (
          <div className="md:col-span-4 bg-white rounded-lg shadow p-4 mt-4">
            <div
              className="p-6 bg-white rounded-lg shadow space-y-4 flex flex-col items-center justify-center"
              style={{ minHeight: "300px", backgroundColor: "#F7FAFC" }}
            >
              <div className="animate-pulse">
                <CheckIcon className="h-12 w-12 text-green-500" />
              </div>
              <p className="text-lg font-semibold text-gray-700">
                Thank you for your feedback!
              </p>
            </div>
          </div>
        ) : (
          <div className="md:col-span-4 bg-white rounded-lg shadow p-4 mt-4">
            <div
              className="p-6 bg-white rounded-lg shadow space-y-4 flex flex-col items-center justify-center"
              style={{ minHeight: "300px", backgroundColor: "#F7FAFC" }}
            >
              <h2 className="text-xl font-semibold text-gray-900">Feedback</h2>
              <p className="text-gray-700">What do you think of Collections?</p>
              {!feedbackGiven && (
                <div className="flex space-x-4">
                  <button
                    onClick={() => handleFeedbackClick("positive")}
                    className="flex items-center justify-center p-2 bg-gray-100 rounded-full hover:bg-gray-200 transition-colors duration-150"
                  >
                    <ThumbUpIcon className="h-6 w-6 text-gray-600" />
                  </button>
                  <button
                    onClick={() => handleFeedbackClick("negative")}
                    className="flex items-center justify-center p-2 bg-gray-100 rounded-full hover:bg-gray-200 transition-colors duration-150"
                  >
                    <ThumbDownIcon className="h-6 w-6 text-gray-600" />
                  </button>
                </div>
              )}
              {feedbackGiven && (
                <>
                  <textarea
                    className="form-textarea mt-4 w-full max-w-md h-24 p-2 border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 transition duration-150 ease-in-out"
                    placeholder="We'd love to hear more about your thoughts..."
                    value={feedbackText}
                    onChange={(e) => setFeedbackText(e.target.value)}
                    style={{ backgroundColor: "#EDF2F7" }}
                  ></textarea>
                  <button
                    onClick={async () => {
                      setIsSubmitting(true);
                      console.log("feedback", feedbackGiven, feedbackText);

                      // Simulate API call delay and successful submission
                      await fetch(
                        `https://backend.scribbl.co/collections/${collection.uuid}/feedback`,
                        {
                          method: "POST",
                          credentials: "include",
                          headers: {
                            "Content-Type": "application/json",
                          },
                          body: JSON.stringify({
                            feedback: `Feedback sentiment: ${feedbackGiven}\nFeedback: ${feedbackText}`,
                          }),
                        }
                      );

                      setIsSubmitting(false);
                      setFeedbackSubmitted(true); // Indicate feedback was submitted

                      // Wait for 2 seconds before resetting the component to its initial state
                      setTimeout(() => {
                        setFeedbackGiven(false);
                        setFeedbackSubmitted(false);
                      }, 2000);
                    }}
                    disabled={isSubmitting}
                    className="mt-4 bg-brand-turq hover:bg-brand-green-lighter1 text-white font-bold py-2 px-4 rounded"
                  >
                    Submit Feedback
                  </button>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  const updateAIChat = (newAIChat) => {
    setCollection((prevCollection) => ({
      ...prevCollection,
      aiChat: JSON.stringify(newAIChat), // Assuming newAIChat is the updated chat array
    }));
  };

  // Component or JSX for Chat tab content
  const ChatContent = () => {
    return (
      <div className="flex-1 overflow-hidden">
        <AIChat
          isCollection={true}
          uuid={collection.uuid}
          userEmail={userEmail}
          updateAIChat={updateAIChat}
          initialAIChat={collection.aiChat}
          isProUser={user.subscription && user.subscription.accountType !== 0}
        />
      </div>
    );
  };

  console.log(collection);

  return (
    <Navbar
      setUserEmail={setUserEmail}
      setUser={setUser}
      setLoading={setLoading}
      loading={loading}
    >
      <div className="flex w-full h-screen">
        {/* Left Panel with Title and Nav */}
        <div className="w-[75%] bg-gray-50">
          <div ref={headerRef} className="bg-white">
            <div className="flex justify-between">
              <div>
                <h1 className="font-bold text-2xl p-5">
                  {collection && collection.title
                    ? collection.title
                    : "Unnamed Collection"}
                </h1>
              </div>

              <div>
                {userAccessType && userAccessType === 1 && (
                  <button
                    onClick={() => setIsShareModalOpen(true)}
                    className="w-[200px] h-10 p-5 m-5 mr-5 bg-brand-turq hover:bg-brand-green-lighter1 text-white font-bold py-2 px-4 rounded-lg transition duration-150 ease-in-out flex items-center"
                    title="Share Collection"
                  >
                    <ShareIcon className="h-5 w-5 mr-2" />
                    Share Collection
                  </button>
                )}
                {userAccessType &&
                  (userAccessType === 2 || userAccessType === 3) && (
                    <button
                      onClick={() => setIsLeaveModalOpen(true)}
                      className="w-[200px] h-10 p-5 m-5 mr-5 bg-white hover:bg-gray-100 text-gray-700 font-bold py-2 px-4 rounded-lg border border-gray-300 transition duration-150 ease-in-out flex items-center"
                      title="Leave Collection"
                    >
                      <ExclamationCircleIcon className="h-5 w-5 mr-2 text-gray-500" />
                      Leave Collection
                    </button>
                  )}
              </div>
            </div>
            <ShareModal
              isOpen={isShareModalOpen}
              onClose={() => setIsShareModalOpen(false)}
              user={user}
              collectionUUID={collectionUUID}
            />
            <LeaveCollectionModal
              isOpen={isLeaveModalOpen}
              onClose={() => setIsLeaveModalOpen(false)}
              onConfirm={handleLeaveCollection}
              userAccessType={userAccessType}
            />
            <div className="bg-white ml-5 mr-5">
              <div className="flex items-end justify-between  border-b">
                <div className="flex space-x-6">{renderTabs()}</div>
              </div>
            </div>
          </div>
          {/* Content Area */}
          <div
            className="overflow-y-auto p-3"
            style={{ maxHeight: "calc(100vh - 140px)" }}
          >
            {renderContent()}
          </div>
        </div>

        {/* Right Panel with Meetings List */}
        <div className="h-full w-[25%] flex flex-col bg-white border-l border-gray-200">
          <div className="p-6 border-b border-gray-200 flex items-center justify-between">
            <h2 className="text-xl font-semibold text-gray-800">Meetings</h2>
            {((userAccessType &&
              (userAccessType === 1 || userAccessType === 2)) ||
              collection?.sharedWithOrg) && (
              <button
                onClick={() => setIsAddMeetingsModalOpen(true)}
                className="p-2 text-brand-turq hover:bg-brand-turq hover:text-white rounded-full transition-colors duration-200"
                title="Add meeting to collection"
              >
                <PlusIcon className="h-5 w-5" />
              </button>
            )}
          </div>
          <div className="flex-grow overflow-y-auto">
            {collection && collection.enhancedRecordings?.length > 0 ? (
              <div className="divide-y divide-gray-200">
                {collection.enhancedRecordings.map((meeting) => (
                  <div
                    key={meeting.ID}
                    className="p-4 hover:bg-gray-50 transition duration-150 ease-in-out cursor-pointer"
                    onClick={() => {
                      window.open(`/meeting/${meeting.uuid}`, "_blank");
                      amplitude
                        .getInstance()
                        .logEvent("Clicked on Collection Meeting");
                    }}
                  >
                    <div className="flex items-start space-x-4">
                      <div className="flex-shrink-0">
                        <img
                          src={meeting.coverThumbnail || "/no-thumbnail.png"}
                          alt=""
                          className="h-16 w-28 object-cover rounded-md"
                        />
                      </div>
                      <div className="flex-1 min-w-0">
                        <p className="text-sm font-medium text-gray-900 line-clamp-2">
                          {meeting.meetingTitle}
                        </p>
                        <p className="mt-1 text-xs text-gray-500">
                          {moment(meeting.CreatedAt).format("MMM D, YYYY")}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-center py-12 px-4">
                <svg
                  className="mx-auto h-12 w-12 text-gray-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    vectorEffect="non-scaling-stroke"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                  />
                </svg>
                <h3 className="mt-2 text-sm font-medium text-gray-900">
                  No meetings in collection
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Add meetings to get started with your collection.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <AddMeetingsToCollectionModal
        isOpen={isAddMeetingsModalOpen}
        onClose={() => {
          setIsAddMeetingsModalOpen(false);
        }}
        collectionTitle={collection?.title}
        collectionUUID={collection?.uuid}
        onAddMeetingsSuccess={fetchCollectionDetails}
        currentMeetings={collection?.enhancedRecordings}
      />
    </Navbar>
  );
};

export default Collection;
