import React, { useState, useEffect, Fragment } from "react";
import {
  PlusIcon,
  UserGroupIcon,
  SearchIcon,
  XIcon,
  GlobeAltIcon,
  UsersIcon,
  ExclamationIcon,
  PencilIcon,
  TrashIcon,
  LightBulbIcon,
  CalendarIcon,
  LockClosedIcon,
} from "@heroicons/react/solid";
import amplitude from "amplitude-js";
import Avatar from "./Avatar";
import { Transition, Dialog } from "@headlessui/react";

const Rules = ({ user }) => {
  const [rules, setRules] = useState([]);
  const [isCreatingRule, setIsCreatingRule] = useState(false);
  const [orgMembers, setOrgMembers] = useState([]);
  const [hasInternalRule, setHasInternalRule] = useState(false);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [ruleToDelete, setRuleToDelete] = useState(null);
  const [editingRule, setEditingRule] = useState(null);

  const [requestState, setRequestState] = useState("initial");

  useEffect(() => {
    if (user && user.role === 2) {
      fetchRules();
      fetchOrgMembers();
    }
  }, [user]);

  useEffect(() => {
    if (rules && user && user.email) {
      setHasInternalRule(
        rules.some((rule) => rule.meetingType === "internal_meetings")
      );
      amplitude.getInstance().logEvent("Viewed Rules Page");
    }
  }, [rules, user]);

  const fetchRules = async () => {
    try {
      const response = await fetch("https://backend.scribbl.co/global-rules", {
        method: "GET",
        credentials: "include",
      });
      if (response.ok) {
        const data = await response.json();
        setRules(data);
      } else {
        console.error("Error fetching rules");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchOrgMembers = async () => {
    try {
      const response = await fetch("https://backend.scribbl.co/orgs/members", {
        method: "GET",
        credentials: "include",
      });
      if (response.ok) {
        const data = await response.json();
        setOrgMembers(data);
      } else {
        console.error("Error fetching org members");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleCreateRule = () => {
    setEditingRule(null);
    setIsCreatingRule(true);
  };

  const handleCancelCreate = () => {
    setIsCreatingRule(false);
    setEditingRule(null);
  };

  const handleEditRule = (rule) => {
    setEditingRule(rule);
    setIsCreatingRule(true);
  };

  const handleDeleteRule = (rule) => {
    setRuleToDelete(rule);
    setIsDeleteModalOpen(true);
  };

  const handleAdminRequest = async () => {
    setRequestState("loading");
    try {
      const response = await fetch(
        "https://backend.scribbl.co/request-admin-access",
        {
          method: "POST",
          credentials: "include",
        }
      );
      if (response.ok) {
        setRequestState("success");
      } else if (response.status === 409) {
        setRequestState("alreadyRequested");
      } else {
        throw new Error("Failed to send admin request");
      }
    } catch (error) {
      console.error("Error requesting admin access:", error);
      setRequestState("error");
    }
  };

  const confirmDeleteRule = () => {
    fetch(`https://backend.scribbl.co/global-rules/${ruleToDelete.ID}`, {
      method: "DELETE",
      credentials: "include",
    })
      .then((response) => {
        if (response.ok) {
          setRules(rules.filter((r) => r.ID !== ruleToDelete.ID));
          setIsDeleteModalOpen(false);
          setRuleToDelete(null);
        } else {
          throw new Error("Failed to delete rule");
        }
      })
      .catch((error) => {
        console.error("Error deleting rule:", error);
      });
  };

  const handleSaveRule = async (newRule) => {
    const isEditing = !!editingRule;
    const url = isEditing
      ? `https://backend.scribbl.co/meeting-global-rules/${editingRule.ID}`
      : "https://backend.scribbl.co/meeting-global-rules";
    const method = isEditing ? "PUT" : "POST";

    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(newRule),
      });

      if (response.ok) {
        const savedRule = await response.json();
        if (isEditing) {
          setRules(rules.map((r) => (r.ID === savedRule.ID ? savedRule : r)));
        } else {
          setRules([...rules, savedRule]);
        }
        setIsCreatingRule(false);
        setEditingRule(null);
        amplitude
          .getInstance()
          .logEvent(
            isEditing
              ? "Updated Meeting Sharing Rule"
              : "Created New Meeting Sharing Rule"
          );
      } else {
        console.error(isEditing ? "Error updating rule" : "Error saving rule");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getNameById = (id) => {
    const member = orgMembers.find((m) => m.ID === id);
    return member ? member.name : "Unknown";
  };

  const Feature = ({ icon, title, description }) => {
    return (
      <div className="flex flex-col items-center text-center">
        <div className="flex items-center justify-center h-16 w-16 rounded-full bg-brand-turq bg-opacity-10 mb-4">
          {icon}
        </div>
        <h3 className="text-lg font-medium text-gray-900 mb-2">{title}</h3>
        <p className="text-sm text-gray-500">{description}</p>
      </div>
    );
  };

  if (user.role !== 2) {
    return (
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white shadow-xl rounded-3xl overflow-hidden">
          <div className="bg-gradient-to-r from-brand-turq to-brand-green-lighter1 px-8 py-8 sm:px-12">
            <h2 className="text-3xl font-extrabold text-white">
              Unlock the Power of Meeting Sharing Rules
            </h2>
            <p className="mt-2 text-lg text-white opacity-90">
              Streamline collaboration and boost productivity across your
              organization
            </p>
          </div>
          <div className="px-8 py-10 sm:px-12">
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-3">
              <Feature
                icon={<LightBulbIcon className="h-8 w-8 text-brand-turq" />}
                title="Intelligent Sharing"
                description="Automatically share the right meetings with the right team members."
              />
              <Feature
                icon={<LockClosedIcon className="h-8 w-8 text-brand-turq" />}
                title="Secure & Controlled"
                description="Set granular permissions to maintain confidentiality while fostering transparency."
              />
              <Feature
                icon={<CalendarIcon className="h-8 w-8 text-brand-turq" />}
                title="Time-Saving"
                description="Eliminate manual sharing and reduce back-and-forth communication about meeting details."
              />
            </div>
            <div className="mt-12 bg-gray-50 rounded-2xl p-6 border border-gray-200">
              <h3 className="text-xl font-semibold text-gray-900 mb-4">
                Ready to Transform Your Team's Collaboration?
              </h3>
              <p className="text-gray-600 mb-6">
                Meeting Sharing Rules are an admin-only feature designed to
                supercharge your organization's efficiency. Request admin access
                to get started!
              </p>
              <div className="space-y-4">
                <button
                  onClick={handleAdminRequest}
                  disabled={
                    requestState === "loading" ||
                    requestState === "success" ||
                    requestState === "alreadyRequested"
                  }
                  className={`w-full px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-turq
                  ${
                    requestState === "initial" || requestState === "error"
                      ? "bg-brand-turq hover:bg-brand-green"
                      : requestState === "loading"
                      ? "bg-brand-green-lighter3 cursor-not-allowed"
                      : "bg-brand-green-lighter1 cursor-not-allowed"
                  }`}
                >
                  {requestState === "initial" && "Request Admin Access"}
                  {requestState === "loading" && "Sending Request..."}
                  {requestState === "success" && "Request Sent"}
                  {requestState === "alreadyRequested" && "Request Pending"}
                  {requestState === "error" && "Try Again"}
                </button>
                {requestState === "success" && (
                  <p className="text-sm text-brand-green mt-2">
                    Great! We've received your admin access request. Please
                    check your inbox to continue the process.
                  </p>
                )}
                {requestState === "alreadyRequested" && (
                  <p className="text-sm text-brand-turq mt-2">
                    You've already submitted a request for admin access. We'll
                    be in touch soon to follow up.
                  </p>
                )}
                {requestState === "error" && (
                  <p className="text-sm text-brand-red mt-2">
                    There was an error sending your request. Please try again or
                    contact support if the issue persists.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-2 sm:px-2 lg:px-4 py-2">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-2xl font-bold text-gray-900">
          Meeting Sharing Rules
        </h1>
        {!isCreatingRule && (
          <button
            onClick={handleCreateRule}
            className="bg-brand-turq hover:bg-brand-green-lighter1 text-white font-semibold py-3 px-6 rounded-lg transition duration-300 ease-in-out flex items-center shadow-lg"
          >
            <PlusIcon className="h-5 w-5 mr-2" />
            Create New Rule
          </button>
        )}
      </div>

      {isCreatingRule ? (
        <CreateRuleForm
          onSave={handleSaveRule}
          onCancel={handleCancelCreate}
          orgMembers={orgMembers}
          hasInternalRule={hasInternalRule}
          editingRule={editingRule}
        />
      ) : rules.length === 0 ? (
        <div className="text-center py-16 bg-white shadow-lg rounded-2xl border border-gray-100">
          <UserGroupIcon className="mx-auto h-16 w-16 text-brand-turq" />
          <h3 className="mt-4 text-xl font-semibold text-gray-900">
            No meeting sharing rules yet
          </h3>
          <p className="mt-2 text-gray-600">
            Create your first meeting sharing rule to automate sharing of
            meetings.
          </p>
          <div className="mt-8">
            <button
              onClick={handleCreateRule}
              className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-lg text-white bg-brand-turq hover:bg-brand-green-lighter1 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-turq transition duration-300 ease-in-out shadow-lg"
            >
              <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              New Rule
            </button>
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {rules.map((rule) => (
            <div key={rule.ID} className="h-full flex-grow">
              <div className="bg-white shadow-lg rounded-2xl p-6 border border-gray-100 transition duration-300 ease-in-out hover:shadow-xl flex flex-col h-full">
                <h3 className="text-xl font-semibold text-gray-900 mb-4">
                  {rule.name}
                </h3>
                <p className="text-sm text-gray-600 mb-4">{rule.description}</p>
                <div className="mt-4">
                  <h4 className="text-sm font-medium text-gray-700 mb-2">
                    Meeting Type:
                  </h4>
                  <span className="bg-brand-turq bg-opacity-10 text-brand-turq text-xs font-medium px-3 py-1 rounded-full">
                    {rule.meetingType === "internal_meetings"
                      ? "Internal"
                      : "External"}
                  </span>
                </div>
                {rule.meetingType === "external_meetings" && (
                  <>
                    <div className="mt-4">
                      <h4 className="text-sm font-medium text-gray-700 mb-2">
                        Contributors:
                      </h4>
                      <div className="flex flex-wrap gap-2">
                        {rule.orgContributes ? (
                          <span className="bg-brand-green-lighter1 bg-opacity-10 text-brand-green-lighter1 text-xs font-medium px-3 py-1 rounded-full">
                            Entire Organization
                          </span>
                        ) : (
                          rule.contributors?.map((contributor) => (
                            <span
                              key={contributor.ID}
                              className="bg-brand-green-lighter1 bg-opacity-10 text-brand-green-lighter1 text-xs font-medium px-3 py-1 rounded-full"
                            >
                              {contributor.name || contributor.email}
                            </span>
                          ))
                        )}
                      </div>
                    </div>
                    <div className="mt-4">
                      <h4 className="text-sm font-medium text-gray-700 mb-2">
                        Shared With:
                      </h4>
                      <div className="flex flex-wrap gap-2">
                        {rule.destinations?.map((destination) => (
                          <span
                            key={destination.ID}
                            className="bg-brand-turq bg-opacity-10 text-brand-turq text-xs font-medium px-3 py-1 rounded-full"
                          >
                            {getNameById(destination.userID)}
                          </span>
                        ))}
                      </div>
                    </div>
                  </>
                )}
                <div className="mt-auto pt-4 flex justify-end space-x-2">
                  {rule.meetingType !== "internal_meetings" && (
                    <button
                      onClick={() => handleEditRule(rule)}
                      className="p-2 text-brand-turq hover:bg-brand-turq hover:bg-opacity-10 rounded-full transition duration-300 ease-in-out"
                    >
                      <PencilIcon className="h-5 w-5" />
                    </button>
                  )}
                  <button
                    onClick={() => handleDeleteRule(rule)}
                    className="p-2 text-red-500 hover:bg-red-50 rounded-full transition duration-300 ease-in-out"
                  >
                    <TrashIcon className="h-5 w-5" />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {/* Delete Confirmation Modal */}
      <Transition.Root show={isDeleteModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setIsDeleteModalOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Delete Rule
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to delete this rule? This action
                        cannot be undone.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={confirmDeleteRule}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => setIsDeleteModalOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

const CreateRuleForm = ({
  onSave,
  onCancel,
  orgMembers,
  hasInternalRule,
  editingRule = null,
}) => {
  const [step, setStep] = useState(editingRule ? 2 : 1);

  const convertDbRuleToFormRule = (dbRule) => {
    return {
      ID: dbRule.ID,
      meetingType: dbRule.meetingType,
      name: dbRule.name,
      description: dbRule.description,
      contributorIds: dbRule.contributors.map((c) => c.ID),
      sharedWithIds: dbRule.destinations.map((d) => d.userID),
      orgContributes: dbRule.orgContributes,
      emailNotifications: dbRule.emailNotifications,
    };
  };

  const [newRule, setNewRule] = useState(
    editingRule
      ? convertDbRuleToFormRule(editingRule)
      : {
          meetingType: "",
          name: "",
          description: "",
          contributorIds: [],
          sharedWithIds: [],
          orgContributes: false,
          emailNotifications: true,
        }
  );

  const isEditing = !!editingRule;

  const handleTypeChange = (meetingType) => {
    setNewRule({
      ...newRule,
      meetingType,
    });
  };

  const handleNextStep = () => {
    if (
      step === 3 ||
      (step === 1 && newRule.meetingType === "internal_meetings")
    ) {
      setNewRule((prevRule) => ({
        ...prevRule,
        name: generateRuleName(),
        description: generateRuleDescription(),
      }));
    }

    if (newRule.meetingType === "internal_meetings") {
      setStep(4); // Skip to summary for internal rules
    } else {
      setStep(step + 1);
    }
  };

  const handlePreviousStep = () => {
    if (isEditing && step === 2) {
      onCancel();
    } else if (newRule.meetingType === "internal_meetings" || step === 2) {
      setStep(1); // Go back to first step for internal rules or from the Contributors step
    } else if (step > 1) {
      setStep(step - 1);
    } else {
      onCancel();
    }
  };

  const isNextDisabled = () => {
    console.log("new rule", newRule);
    if (step === 1) {
      return !newRule.meetingType;
    }
    if (newRule.meetingType === "external_meetings") {
      if (step === 2) {
        return !newRule.orgContributes && newRule.contributorIds.length === 0;
      }
      if (step === 3) {
        return newRule.sharedWithIds.length === 0;
      }
    }
    return false;
  };

  const generateRuleName = () => {
    if (newRule.meetingType === "internal_meetings") {
      return "Internal Meeting Sharing Rule";
    } else {
      const contributorText = newRule.orgContributes
        ? "All Team Members"
        : `${newRule.contributorIds.length} Contributors`;
      return `External Meeting Sharing: ${contributorText}`;
    }
  };

  const generateRuleDescription = () => {
    if (newRule.meetingType === "internal_meetings") {
      return "Automatically share internal meetings with all participants on the calendar invite.";
    } else {
      const contributorText = newRule.orgContributes
        ? "all team members (including future members)"
        : `${newRule.contributorIds.length} selected contributors`;
      const sharedWithText = `${newRule.sharedWithIds.length} selected team members`;
      return `Share external meetings from ${contributorText} with ${sharedWithText}.`;
    }
  };

  return (
    <div className="bg-white shadow-xl rounded-3xl p-8 border border-gray-100 flex flex-col h-[calc(100vh-180px)]">
      <div className="flex-grow overflow-y-auto">
        {!isEditing && step === 1 && (
          <div className="space-y-8 ml-2">
            <h3 className="text-2xl font-semibold text-gray-900 mb-6">
              What type of meeting sharing rule do you want to set up?
            </h3>
            <div className="flex gap-4">
              <button
                onClick={() => handleTypeChange("external_meetings")}
                className="w-80 text-left p-6 border border-gray-200 rounded-xl hover:border-brand-turq focus:outline-none focus:ring-2 focus:ring-brand-turq transition duration-300 ease-in-out group"
              >
                <GlobeAltIcon className="w-12 h-12 text-brand-turq mb-4" />
                <h4 className="text-lg font-medium mb-2">
                  External Meeting Rule
                </h4>
                <p className="text-sm text-gray-600">
                  For meetings with people outside your organization. Share
                  meetings with specific team members.
                </p>
              </button>
              <button
                onClick={() => handleTypeChange("internal_meetings")}
                disabled={hasInternalRule}
                className={`w-80 text-left p-6 border border-gray-200 rounded-xl transition duration-300 ease-in-out group ${
                  hasInternalRule
                    ? "opacity-50 cursor-not-allowed"
                    : "hover:border-brand-turq focus:outline-none focus:ring-2 focus:ring-brand-turq"
                }`}
              >
                <UsersIcon className="w-12 h-12 text-brand-turq mb-4" />
                <h4 className="text-lg font-medium mb-2">
                  Internal Meeting Rule
                </h4>
                <p className="text-sm text-gray-600">
                  {hasInternalRule
                    ? "An internal meeting rule already exists."
                    : "For internal team meetings. Automatically share meetings with all calendar invite participants."}
                </p>
              </button>
            </div>
          </div>
        )}

        {step === 2 && (
          <div className="space-y-5 ml-2">
            <h3 className="text-xl font-semibold text-gray-900">
              Select External Meeting Contributors
            </h3>
            <p className="text-sm text-gray-600 mb-3">
              Contributors are team members whose external meetings will be
              automatically shared. Their meetings will be distributed to the
              selected recipients in the next step.
            </p>

            <div className="mb-2">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={newRule.orgContributes}
                  onChange={(e) =>
                    setNewRule({ ...newRule, orgContributes: e.target.checked })
                  }
                  className="form-checkbox h-5 w-5 text-brand-turq rounded"
                />
                <span className="ml-2 text-sm text-gray-700">
                  Entire team contributes (including future members)
                </span>
              </label>
            </div>
            {!newRule.orgContributes && (
              <UserSelectionStep
                members={orgMembers}
                selectedIds={newRule.contributorIds}
                onSelectionChange={(id) => {
                  const updatedIds = newRule.contributorIds.includes(id)
                    ? newRule.contributorIds.filter((cId) => cId !== id)
                    : [...newRule.contributorIds, id];
                  setNewRule({ ...newRule, contributorIds: updatedIds });
                }}
              />
            )}
          </div>
        )}

        {step === 3 && (
          <div className="space-y-5 ml-2">
            <h3 className="text-xl font-semibold text-gray-900">
              Select Who Will Have Access to Contributor Meetings
            </h3>
            <p className="text-sm text-gray-600 mb-3">
              Choose the team members who will automatically be granted access
              to the external meetings of the contributors selected in the
              previous step. These members will be able to view the shared
              meetings as soon as they are available.
            </p>

            <div className="mt-4">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={newRule.emailNotifications}
                  onChange={(e) =>
                    setNewRule({
                      ...newRule,
                      emailNotifications: e.target.checked,
                    })
                  }
                  className="form-checkbox h-5 w-5 text-brand-turq rounded"
                />
                <span className="ml-2 text-sm text-gray-700">
                  Send email notifications for shared meetings
                </span>
              </label>
            </div>

            <UserSelectionStep
              members={orgMembers}
              selectedIds={newRule.sharedWithIds}
              onSelectionChange={(id) => {
                const updatedIds = newRule.sharedWithIds.includes(id)
                  ? newRule.sharedWithIds.filter((sId) => sId !== id)
                  : [...newRule.sharedWithIds, id];
                setNewRule({ ...newRule, sharedWithIds: updatedIds });
              }}
            />
          </div>
        )}

        {step === 4 && (
          <div className="space-y-6">
            <h3 className="text-2xl font-semibold text-gray-900 mb-6">
              Review and Confirm Your Rule
            </h3>
            <div className="bg-white border border-gray-200 rounded-xl shadow-sm p-6">
              <div className="space-y-2">
                <div>
                  <h4 className="font-medium text-gray-900">Rule Type</h4>
                  <p className="text-sm text-gray-600">
                    {newRule.meetingType === "internal_meetings"
                      ? "Internal Meeting Rule"
                      : "External Meeting Rule"}
                  </p>
                </div>
                <div>
                  <h4 className="font-medium text-gray-900 mb-0.5">
                    Rule Name
                  </h4>
                  <p className="text-sm text-gray-600">{newRule.name}</p>
                </div>
                <div>
                  <h4 className="font-medium text-gray-900 mb-0.5">
                    Description
                  </h4>
                  <p className="text-sm text-gray-600">{newRule.description}</p>
                </div>
                {newRule.meetingType === "external_meetings" && (
                  <>
                    <div>
                      <h4 className="font-medium text-gray-900 mb-0.5">
                        Contributors
                      </h4>
                      <p className="text-sm text-gray-600">
                        {newRule.orgContributes
                          ? "All team members (including future members) will contribute their external meetings."
                          : newRule.contributorIds.length > 0
                          ? `External meetings from ${orgMembers
                              .filter((member) =>
                                newRule.contributorIds.includes(member.ID)
                              )
                              .map((member) => member.name)
                              .join(", ")} will be shared.`
                          : "No contributors selected. Please go back and select contributors."}
                      </p>
                    </div>
                    <div>
                      <h4 className="font-medium text-gray-900 mb-0.5">
                        Shared With
                      </h4>
                      <p className="text-sm text-gray-600">
                        {newRule.sharedWithIds.length > 0
                          ? `The shared external meetings will be accessible to ${orgMembers
                              .filter((member) =>
                                newRule.sharedWithIds.includes(member.ID)
                              )
                              .map((member) => member.name)
                              .join(", ")}.`
                          : "No team members selected to receive shared meetings. Please go back and select recipients."}
                      </p>
                    </div>
                    <div>
                      <h4 className="font-medium text-gray-900 mb-0.5">
                        Email Notifications
                      </h4>
                      <p className="text-sm text-gray-600">
                        {newRule.emailNotifications
                          ? "Email notifications will be sent for shared meetings."
                          : "Email notifications are disabled for shared meetings."}
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="mt-8 flex justify-between items-center">
        <button
          onClick={handlePreviousStep}
          className="px-6 py-3 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-turq transition duration-300 ease-in-out"
        >
          {step > 1 ? "Back" : "Cancel"}
        </button>
        <button
          onClick={step < 4 ? handleNextStep : () => onSave(newRule)}
          disabled={isNextDisabled()}
          className={`px-6 py-3 border border-transparent text-sm font-medium rounded-md shadow-sm text-white transition duration-300 ease-in-out ${
            isNextDisabled()
              ? "bg-gray-300 cursor-not-allowed"
              : "bg-brand-turq hover:bg-brand-turq-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-turq"
          }`}
        >
          {step < 4 ? "Next" : isEditing ? "Save Changes" : "Create Rule"}
        </button>
      </div>
    </div>
  );
};

const UserSelectionStep = ({ members, selectedIds, onSelectionChange }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredMembers = members.filter(
    (member) =>
      member.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      member.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const selectedMembers = members.filter((member) =>
    selectedIds.includes(member.ID)
  );
  const unselectedMembers = filteredMembers.filter(
    (member) => !selectedIds.includes(member.ID)
  );

  const handleSelectionChange = (memberId) => {
    onSelectionChange(memberId);
    setSearchTerm("");
  };

  return (
    <div>
      <div className="flex space-x-4">
        <div className="w-1/2">
          <h5 className="text-sm font-medium text-gray-700 mb-1">
            Available Users
          </h5>
          <div className="relative mb-1">
            <input
              type="text"
              placeholder="Search users..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full border border-gray-300 rounded-md shadow-sm py-1 pl-8 pr-2 focus:outline-none focus:ring-brand-turq focus:border-brand-turq text-sm"
            />
            <SearchIcon className="h-4 w-4 text-gray-400 absolute left-2 top-1.5" />
          </div>
          <div className="bg-gray-50 p-2 rounded-lg h-52 overflow-y-auto">
            {unselectedMembers.map((member) => (
              <UserItem
                key={member.ID}
                member={member}
                onAction={() => handleSelectionChange(member.ID)}
                actionIcon={<PlusIcon className="h-4 w-4 text-green-500" />}
                selectable={true}
              />
            ))}
          </div>
        </div>
        <div className="w-1/2">
          <h5 className="text-sm font-medium text-gray-700 mb-1">
            Selected Users
          </h5>
          <div className="bg-gray-50 p-2 rounded-lg h-60 overflow-y-auto">
            {selectedMembers.map((member) => (
              <UserItem
                key={member.ID}
                member={member}
                onAction={() => onSelectionChange(member.ID)}
                actionIcon={<XIcon className="h-4 w-4 text-red-500" />}
                selectable={false}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const UserItem = ({ member, onAction, actionIcon, selectable }) => (
  <div
    className={`flex items-center justify-between p-2 rounded-md ${
      selectable ? "hover:bg-gray-200 cursor-pointer" : "hover:bg-gray-100"
    }`}
    onClick={selectable ? onAction : undefined}
  >
    <div className="flex items-center">
      <Avatar name={member.name} email={member.email} />
      <div className="ml-3">
        <p className="text-sm font-medium text-gray-900">{member.name}</p>
        <p className="text-xs text-gray-500">{member.email}</p>
      </div>
    </div>
    <button
      onClick={onAction}
      className="p-1 rounded-full hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-turq"
    >
      {actionIcon}
    </button>
  </div>
);

export default Rules;
