import React, { useState, useEffect } from "react";

import { LinkIcon, MinusIcon } from "@heroicons/react/solid";
import GoogleDriveFolderPicker from "./GoogleDriveFilePicker";
import amplitude from "amplitude-js";

const CollectionIntegrationsTab = ({ collection }) => {
  const [slackConnected, setSlackConnected] = useState(false);
  const [slackChannels, setSlackChannels] = useState([]);
  const [selectedSlackChannels, setSelectedSlackChannels] = useState([]);
  const [googleDriveConnected, setGoogleDriveConnected] = useState(false);
  const [selectedGoogleDriveFolders, setSelectedGoogleDriveFolders] = useState(
    []
  );

  useEffect(() => {
    fetchSlackStatus();
    fetchGoogleDriveStatus();
  }, [collection]);

  const fetchSlackStatus = async () => {
    try {
      const response = await fetch("https://backend.scribbl.co/slack/status", {
        credentials: "include",
      });
      if (response.ok) {
        const data = await response.json();
        setSlackConnected(data.connected);
        if (data.connected) {
          fetchSlackChannels();
          fetchSlackChannelsForCollection();
        }
      }
    } catch (error) {
      console.error("Error fetching Slack status:", error);
    }
  };

  const fetchGoogleDriveStatus = async () => {
    try {
      const response = await fetch(
        "https://backend.scribbl.co/google-drive/status",
        {
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();
        setGoogleDriveConnected(data.connected);
        if (data.connected) {
          fetchGoogleDriveFoldersForCollection();
        }
      }
    } catch (error) {
      console.error("Error fetching Google Drive status:", error);
    }
  };

  const handleConnectSlack = async () => {
    try {
      const response = await fetch(
        `https://backend.scribbl.co/slack/connect?collection_uuid=${collection.uuid}`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();

        amplitude
          .getInstance()
          .logEvent("Collection dashboard: integrations - slack connected");

        window.location.href = data.authUrl;
      }
    } catch (error) {
      console.error("Error connecting to Slack:", error);
    }
  };

  const handleDisconnectSlack = async () => {
    try {
      await fetch("https://backend.scribbl.co/slack/disconnect", {
        method: "POST",
        credentials: "include",
      });
      setSlackConnected(false);
      setSelectedSlackChannels([]);
    } catch (error) {
      console.error("Error disconnecting from Slack:", error);
    }
  };

  const fetchSlackChannels = async () => {
    try {
      const response = await fetch("https://backend.scribbl.co/slack/channels", {
        credentials: "include",
      });
      if (response.ok) {
        const data = await response.json();
        setSlackChannels(data.channels);
      }
    } catch (error) {
      console.error("Error fetching Slack channels:", error);
    }
  };

  const handleAddSlackChannel = async (channelId) => {
    if (selectedSlackChannels.some((channel) => channel.id === channelId)) {
      console.log("This channel is already added.");
      return;
    }

    try {
      await fetch("https://backend.scribbl.co/slack/channel", {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ channelId, collectionId: collection.ID }),
      });
      fetchSlackChannelsForCollection();

      amplitude
        .getInstance()
        .logEvent("Collection dashboard: integrations - slack channel added");
    } catch (error) {
      console.error("Error adding Slack channel:", error);
    }
  };

  const handleRemoveSlackChannel = async (channelId) => {
    try {
      const response = await fetch(
        `https://backend.scribbl.co/slack/channel/${channelId}`,
        {
          method: "DELETE",
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ collectionId: collection.ID }),
        }
      );

      if (response.ok) {
        fetchSlackChannelsForCollection();
      } else {
        const errorData = await response.json();
        console.error("Error removing Slack channel:", errorData.message);
      }
    } catch (error) {
      console.error("Error removing Slack channel:", error);
    }
  };

  const fetchSlackChannelsForCollection = async () => {
    try {
      const response = await fetch(
        `https://backend.scribbl.co/slack/channels/${collection.ID}`,
        {
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();
        setSelectedSlackChannels(data.channels);
      }
    } catch (error) {
      console.error("Error fetching Slack channels for collection:", error);
    }
  };

  const handleConnectGoogleDrive = async () => {
    try {
      const response = await fetch(
        `https://backend.scribbl.co/google-drive/connect`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();
        if (data.status === "success") {
          setGoogleDriveConnected(true);

          amplitude
            .getInstance()
            .logEvent("Collection dashboard: integrations - drive connected");
        }
      }
    } catch (error) {
      console.error("Error connecting to Google Drive:", error);
    }
  };

  const handleDisconnectGoogleDrive = async () => {
    try {
      await fetch("https://backend.scribbl.co/google-drive/disconnect", {
        method: "POST",
        credentials: "include",
      });
      setGoogleDriveConnected(false);
      setSelectedGoogleDriveFolders([]);
    } catch (error) {
      console.error("Error disconnecting from Google Drive:", error);
    }
  };

  const handleSelectGoogleDriveFolder = async (folderId, folderName) => {
    if (selectedGoogleDriveFolders.some((folder) => folder.id === folderId)) {
      console.log("This folder is already added.");
      return;
    }

    try {
      await fetch("https://backend.scribbl.co/google-drive/folder", {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          folderId,
          folderName,
          collectionId: collection.ID,
        }),
      });
      fetchGoogleDriveFoldersForCollection();

      amplitude
        .getInstance()
        .logEvent("Collection dashboard: integrations - drive folder added");
    } catch (error) {
      console.error("Error adding Google Drive folder:", error);
    }
  };
  const handleRemoveGoogleDriveFolder = async (folderId) => {
    try {
      const response = await fetch(
        `https://backend.scribbl.co/google-drive/folder?folderId=${folderId}`,
        {
          method: "DELETE",
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ collectionId: collection.ID }),
        }
      );

      if (response.ok) {
        fetchGoogleDriveFoldersForCollection();
      } else {
        const errorData = await response.json();
        console.error("Error removing Google Drive folder:", errorData.message);
      }
    } catch (error) {
      console.error("Error removing Google Drive folder:", error);
    }
  };

  const fetchGoogleDriveFoldersForCollection = async () => {
    try {
      const response = await fetch(
        `https://backend.scribbl.co/google-drive/folders/${collection.ID}`,
        {
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();
        setSelectedGoogleDriveFolders(data.folders);
      }
    } catch (error) {
      console.error(
        "Error fetching Google Drive folders for collection:",
        error
      );
    }
  };

  useEffect(() => {
    if (slackConnected) {
      fetchSlackChannels();
    }
  }, [slackConnected, googleDriveConnected]);

  return (
    <div className="space-y-8">
      {/* Slack Integration */}
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Slack Integration
          </h3>
          <div className="mt-2 max-w-xl text-sm text-gray-500">
            <p>
              Automatically send meeting notes and action items to your chosen
              Slack channels for any meetings added to this collection.
            </p>
          </div>
          <div className="mt-5">
            {!slackConnected ? (
              <button
                type="button"
                onClick={handleConnectSlack}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-brand-turq hover:bg-brand-green-lighter1 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-turq"
              >
                <LinkIcon className="mr-2 h-5 w-5" />
                Connect to Slack
              </button>
            ) : (
              <div>
                <button
                  type="button"
                  onClick={handleDisconnectSlack}
                  className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-turq"
                >
                  Disconnect from Slack
                </button>
                <div className="mt-4">
                  <label
                    htmlFor="slack-channel"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Select Slack Channels
                  </label>
                  <select
                    id="slack-channel"
                    name="slack-channel"
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-brand-turq focus:border-brand-turq sm:text-sm rounded-md"
                    onChange={(e) => handleAddSlackChannel(e.target.value)}
                    value=""
                  >
                    <option value="">Add a channel</option>
                    {slackChannels.map((channel) => (
                      <option key={channel.id} value={channel.id}>
                        {channel.name}
                      </option>
                    ))}
                  </select>
                  <div className="mt-2 space-y-2">
                    {selectedSlackChannels.map((channel) => {
                      return (
                        <div
                          key={channel.id}
                          className="flex items-center justify-between bg-gray-100 px-3 py-2 rounded-md"
                        >
                          <span>
                            {channel ? channel.name : "Unknown Channel"}
                          </span>
                          <button
                            onClick={() => handleRemoveSlackChannel(channel.id)}
                            className="text-red-600 hover:text-red-800"
                          >
                            <MinusIcon className="h-5 w-5" />
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Google Drive Integration */}
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Google Drive Integration
          </h3>
          <div className="mt-2 max-w-xl text-sm text-gray-500">
            <p>
              Automatically create Google Docs in your selected Google Drive
              folders with meeting notes and action items. A new Doc is
              generated for each meeting added to this collection.
            </p>
          </div>
          <div className="mt-5">
            {!googleDriveConnected ? (
              <button
                type="button"
                onClick={handleConnectGoogleDrive}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-brand-turq hover:bg-brand-green-lighter1 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-turq"
              >
                <LinkIcon className="mr-2 h-5 w-5" />
                Connect to Google Drive
              </button>
            ) : (
              <div>
                <button
                  type="button"
                  onClick={handleDisconnectGoogleDrive}
                  className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-turq"
                >
                  Disconnect from Google Drive
                </button>
                <div className="mt-4">
                  <label
                    htmlFor="google-drive-folder"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Select Google Drive Folder
                  </label>
                  <GoogleDriveFolderPicker
                    onFolderSelect={handleSelectGoogleDriveFolder}
                    disabled={!googleDriveConnected}
                  />
                  <div className="mt-2 space-y-2">
                    {selectedGoogleDriveFolders.map((folder) => (
                      <div
                        key={folder.id}
                        className="flex items-center justify-between bg-gray-100 px-3 py-2 rounded-md"
                      >
                        <span>{folder.name}</span>
                        <button
                          onClick={() =>
                            handleRemoveGoogleDriveFolder(folder.id)
                          }
                          className="text-red-600 hover:text-red-800"
                        >
                          <MinusIcon className="h-5 w-5" />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollectionIntegrationsTab;
