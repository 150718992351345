// GoogleDriveFolderPicker.js
import React, { useEffect, useState } from "react";

const GoogleDriveFolderPicker = ({ onFolderSelect, disabled }) => {
  const [pickerApiLoaded, setPickerApiLoaded] = useState(false);
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    // Load the Google Picker API
    const script = document.createElement("script");
    script.src = "https://apis.google.com/js/api.js";
    script.onload = () => loadPickerApi();
    document.body.appendChild(script);

    fetchAccessToken();

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const loadPickerApi = () => {
    window.gapi.load("picker", { callback: () => setPickerApiLoaded(true) });
  };

  const fetchAccessToken = async () => {
    try {
      const response = await fetch(
        "https://backend.scribbl.co/google-drive/access-token",
        {
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();
        setAccessToken(data.accessToken);
      }
    } catch (error) {
      console.error("Error fetching access token:", error);
    }
  };

  const openPicker = () => {
    if (!pickerApiLoaded) return;

    const picker = new window.google.picker.PickerBuilder()
      .addView(
        new window.google.picker.DocsView()
          .setIncludeFolders(true)
          .setSelectFolderEnabled(true)
          .setMimeTypes("application/vnd.google-apps.folder") // Filter for folders only
      )
      .setOAuthToken(accessToken)
      .setCallback(pickerCallback)
      .build();
    picker.setVisible(true);
  };

  const pickerCallback = (data) => {
    if (data.action === window.google.picker.Action.PICKED) {
      const folderId = data.docs[0].id;
      const folderName = data.docs[0].name;
      onFolderSelect(folderId, folderName);
    }
  };

  return (
    <button
      onClick={openPicker}
      disabled={disabled || !pickerApiLoaded}
      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-brand-turq focus:border-brand-turq sm:text-sm rounded-md"
    >
      Select Google Drive Folder
    </button>
  );
};

export default GoogleDriveFolderPicker;
