import React, { useState, useEffect, Fragment } from "react";
import Navbar from "../Navbar";
import { Link } from "react-router-dom";
import CreateCollectionModal from "./CreateCollection/CreateCollectionModal";
import {
  CalendarIcon,
  DocumentTextIcon,
  ShareIcon,
  UsersIcon,
  ExclamationIcon,
  XIcon,
  TrashIcon,
  EyeIcon,
  FolderIcon,
  PlusIcon,
  SearchIcon,
  ClockIcon,
} from "@heroicons/react/outline";
import moment from "moment";
import amplitude from "amplitude-js";
import ManageTeamModal from "../ManageTeamModal";
import { Dialog, Transition } from "@headlessui/react";

const Collections = () => {
  const [collections, setCollections] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showCreateCollectionModal, setShowCreateCollectionModal] =
    useState(false);
  const [user, setUser] = useState(null);
  const [emptyState, setEmptyState] = useState(false);

  const [showManageTeamModal, setShowManageTeamModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [collectionToDelete, setCollectionToDelete] = useState(null);

  const [searchQuery, setSearchQuery] = useState("");
  const [showSearchTooltip, setShowSearchTooltip] = useState(false);

  const handleManageTeamClick = () => {
    setShowManageTeamModal(true);
    amplitude
      .getInstance()
      .logEvent("Opened Manage Team Modal from Collections");
  };

  console.log("user", user);

  useEffect(() => {
    const fetchCollections = async () => {
      try {
        console.log("whats user", user);
        if (!user.orgID) {
          setEmptyState(true);
          setLoading(false);
          return;
        }

        const response = await fetch("https://backend.scribbl.co/collections", {
          method: "GET",
          credentials: "include",
        });
        if (response.ok) {
          const data = await response.json();
          if (data.length === 0) {
            setEmptyState(true);
            setLoading(false);
          }

          setCollections(data);
        } else {
          console.error("Error fetching collections");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    if (user) {
      fetchCollections();
    }
  }, [user]);

  useEffect(() => {
    if (collections && user && user.email) {
      setLoading(false);
      amplitude.getInstance().logEvent("Viewed Collections Page");
    }
  }, [collections, user]);

  const formatAccessList = (usersAccess) => {
    const maxVisibleUsers = 5;
    const visibleUsers = usersAccess
      .slice(0, maxVisibleUsers)
      .map((access) => access.User.name || access.User.email);
    const remainingCount = usersAccess.length - maxVisibleUsers;

    return {
      visibleUsers: visibleUsers.join(", "),
      remainingCount,
    };
  };

  const openDeleteModalClicked = (collection) => {
    setCollectionToDelete(collection);
    setOpenDeleteModal(true);
  };

  const deleteCollection = async () => {
    if (collectionToDelete) {
      try {
        const response = await fetch(
          `https://backend.scribbl.co/collections/${collectionToDelete.uuid}`,
          {
            method: "DELETE",
            credentials: "include",
          }
        );

        if (response.ok) {
          setCollections(
            collections.filter((c) => c.uuid !== collectionToDelete.uuid)
          );
          setOpenDeleteModal(false);
        } else {
          console.error("Failed to delete collection");
        }
      } catch (error) {
        console.error("Error deleting collection:", error);
      }
    }
  };

  const isPartialDateMatch = (createdDate, searchStr) => {
    const lowerSearchStr = searchStr.toLowerCase();
    return (
      createdDate.format("YYYY").includes(lowerSearchStr) ||
      createdDate.format("MMMM").toLowerCase().includes(lowerSearchStr) ||
      createdDate.format("MMM").toLowerCase().includes(lowerSearchStr) ||
      createdDate.format("dddd").toLowerCase().includes(lowerSearchStr) ||
      createdDate.format("ddd").toLowerCase().includes(lowerSearchStr) ||
      createdDate.format("Do").toLowerCase().includes(lowerSearchStr)
    );
  };

  const parseFlexibleDate = (dateStr) => {
    const formats = [
      "YYYY-MM-DD",
      "MM/DD/YYYY",
      "DD/MM/YYYY",
      "MMM D, YYYY",
      "MMMM D, YYYY",
      "D MMM YYYY",
      "D MMMM YYYY",
      "YYYY",
      "MMM",
      "MMMM",
      "MMM YYYY",
      "MMMM YYYY",
      "MMMM D",
      "MMM D",
      "D MMMM",
      "D MMM",
    ];

    const parsedDate = moment(dateStr, formats, true);
    return parsedDate.isValid() ? parsedDate : null;
  };

  const getSearchMatches = (collection, searchLower) => {
    const matches = [];
    if (collection.title.toLowerCase().includes(searchLower)) {
      matches.push({ type: "title", text: collection.title });
    }
    if (collection.description.toLowerCase().includes(searchLower)) {
      matches.push({ type: "description", text: collection.description });
    }
    collection.enhancedRecordings.forEach((recording) => {
      if (recording.meetingTitle.toLowerCase().includes(searchLower)) {
        matches.push({ type: "meeting", text: recording.meetingTitle });
      }
    });
    collection.usersAccess.forEach((access) => {
      if (
        access.User.name &&
        access.User.name.toLowerCase().includes(searchLower)
      ) {
        matches.push({ type: "user", text: access.User.name });
      } else if (access.User.email.toLowerCase().includes(searchLower)) {
        matches.push({ type: "user", text: access.User.email });
      }
    });

    // Flexible date matching
    const createdDate = moment(collection.CreatedAt);
    const searchDate = parseFlexibleDate(searchLower);

    if (searchDate) {
      const formatUsed = searchDate.creationData().format;
      if (typeof formatUsed === "string") {
        if (
          formatUsed.includes("YYYY") &&
          createdDate.isSame(searchDate, "year")
        ) {
          matches.push({
            type: "date",
            text: createdDate.format("MMM D, YYYY"),
          });
        } else if (
          (formatUsed.includes("MMM") || formatUsed.includes("MMMM")) &&
          createdDate.isSame(searchDate, "month")
        ) {
          matches.push({
            type: "date",
            text: createdDate.format("MMM D, YYYY"),
          });
        } else if (
          formatUsed.includes("D") &&
          createdDate.isSame(searchDate, "day")
        ) {
          matches.push({
            type: "date",
            text: createdDate.format("MMM D, YYYY"),
          });
        }
      }
    } else if (
      createdDate.format("MMM D, YYYY").toLowerCase().includes(searchLower) ||
      isPartialDateMatch(createdDate, searchLower)
    ) {
      matches.push({ type: "date", text: createdDate.format("MMM D, YYYY") });
    }

    return matches;
  };
  const filteredCollections = collections
    ? collections
        .map((collection) => {
          const searchLower = searchQuery.toLowerCase();
          const matches = getSearchMatches(collection, searchLower);
          return { ...collection, matches };
        })
        .filter((collection) => collection.matches.length > 0)
    : [];

  return (
    <Navbar loading={loading} setUser={setUser}>
      {emptyState ? (
        <div className="h-full flex flex-col">
          <div className="">
            <div className="flex flex-col items-center justify-start min-h-full w-full pt-8 px-4">
              <h1 className="text-4xl font-semibold text-gray-800 mb-2">
                Welcome to Collections
              </h1>
              <p className="text-xl mt-4 max-w-[60vw] text-gray-600 mb-6 text-center px-4">
                Group meetings in collections and share them with your team. Let
                Scribbl work for you and pull out AI insights about your
                meetings or answer any questions you might have
              </p>
              {user && !user.orgID ? (
                <div className="flex flex-col items-center">
                  <p className="text-lg text-brand-turq mb-4">
                    Join a team to start creating collections
                  </p>
                  <button
                    onClick={handleManageTeamClick}
                    className="bg-brand-turq hover:bg-brand-green-lighter2 text-white font-bold py-2 px-6 rounded-lg transition duration-150 ease-in-out flex items-center"
                  >
                    <UsersIcon className="h-5 w-5 mr-2" />
                    Create or Join a Team
                  </button>
                </div>
              ) : (
                <button
                  onClick={() => setShowCreateCollectionModal(true)}
                  className="mt-4 bg-brand-turq hover:bg-brand-green-lighter2 text-white font-bold py-2 px-6 rounded-lg transition duration-150 ease-in-out"
                >
                  Create Your First Collection
                </button>
              )}
              <img
                src="/collections-demo.png"
                alt="Collection screenshot"
                className="mt-5 w-full max-w-3xl rounded-lg shadow-xl"
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="min-h-screen overflow-hidden bg-gray-50">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 flex flex-col h-[calc(100vh-10px)]">
            <div className="sm:flex sm:items-center sm:justify-between mb-4">
              <div>
                <h1 className="text-3xl font-bold text-brand-neutral-dark">
                  Collections
                </h1>
                <p className="mt-2 text-sm text-brand-gray-lighter1">
                  Organize, analyze, and collaborate on your meetings
                  effortlessly.
                </p>
              </div>
              <div className="mt-4 sm:mt-0">
                <button
                  onClick={() => setShowCreateCollectionModal(true)}
                  className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-brand-turq hover:bg-brand-green-lighter2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-turq transition duration-150 ease-in-out"
                >
                  <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                  Create Collection
                </button>
              </div>
            </div>
            <div className="mb-4">
              <div className="relative rounded-md shadow-sm">
                <input
                  type="text"
                  name="search"
                  id="search"
                  className="block w-full pr-10 sm:text-sm border-gray-300 rounded-md focus:ring-brand-turq focus:border-brand-turq"
                  placeholder="Search collections"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <SearchIcon
                    className="h-5 w-5 text-brand-gray-lighter1"
                    aria-hidden="true"
                  />
                </div>
              </div>
              <div className="relative">
                <button
                  className="text-sm text-brand-turq hover:text-brand-green-lighter2 focus:outline-none"
                  onMouseEnter={() => setShowSearchTooltip(true)}
                  onMouseLeave={() => setShowSearchTooltip(false)}
                >
                  How does search work?
                </button>
                {showSearchTooltip && (
                  <div className="absolute z-10 w-64 p-2 mt-1 text-sm text-white bg-gray-800 rounded-md shadow-lg">
                    Search looks for matches in collection titles, meeting
                    titles, user names, and creation dates.
                  </div>
                )}
              </div>
            </div>

            <div className="h-full overflow-auto">
              <ul className="space-y-4">
                {filteredCollections &&
                  filteredCollections.map((collection) => {
                    const ownerAccess = collection.usersAccess.find(
                      (access) => access.accessType === 1
                    );
                    const isShared =
                      ownerAccess && ownerAccess.userID !== user.ID;
                    const { visibleUsers, remainingCount } = formatAccessList(
                      collection.usersAccess
                    );

                    const getMatchIcon = (type) => {
                      switch (type) {
                        case "title":
                          return (
                            <FolderIcon className="h-4 w-4 text-brand-turq" />
                          );
                        case "description":
                          return (
                            <DocumentTextIcon className="h-4 w-4 text-brand-turq" />
                          );
                        case "meeting":
                          return (
                            <CalendarIcon className="h-4 w-4 text-brand-turq" />
                          );
                        case "user":
                          return (
                            <UsersIcon className="h-4 w-4 text-brand-turq" />
                          );
                        case "date":
                          return (
                            <ClockIcon className="h-4 w-4 text-brand-turq" />
                          );
                        default:
                          return null;
                      }
                    };

                    return (
                      <li
                        key={collection.ID}
                        className="bg-white rounded-lg shadow-sm overflow-hidden transition-all duration-200 hover:shadow-md"
                      >
                        <div className="p-6">
                          <div className="flex items-center justify-between mb-4">
                            <h3 className="text-lg font-semibold text-brand-neutral-dark">
                              {collection.title}
                            </h3>
                            <div className="flex items-center space-x-2">
                              <Link
                                target="_blank"
                                rel="noopener noreferrer"
                                to={`/collection/${collection.uuid}`}
                                className="inline-flex items-center px-3 py-1.5 border border-transparent text-sm font-medium rounded-md text-white bg-brand-turq hover:bg-brand-green-lighter2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-turq"
                              >
                                <EyeIcon
                                  className="-ml-0.5 mr-2 h-4 w-4"
                                  aria-hidden="true"
                                />
                                View
                              </Link>
                              <button
                                onClick={() =>
                                  openDeleteModalClicked(collection)
                                }
                                className="p-1.5 rounded-md text-brand-gray-lighter1 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-turq"
                              >
                                <TrashIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                          <p className="text-sm text-brand-gray-lighter1 mb-4">
                            {collection.description}
                          </p>
                          <div className="grid grid-cols-2 gap-4 text-sm text-brand-gray-lighter1">
                            <div className="flex items-center">
                              <CalendarIcon
                                className="h-4 w-4 mr-2 text-brand-turq"
                                aria-hidden="true"
                              />
                              Created{" "}
                              {moment(collection.CreatedAt).format(
                                "MMM D, YYYY"
                              )}
                            </div>
                            <div className="flex items-center">
                              <UsersIcon
                                className="h-4 w-4 mr-2 text-brand-turq"
                                aria-hidden="true"
                              />
                              {collection.sharedWithOrg ? (
                                "Shared with team"
                              ) : (
                                <>
                                  Shared with {visibleUsers}
                                  {remainingCount > 0 && ` +${remainingCount}`}
                                </>
                              )}
                            </div>
                            <div className="flex items-center">
                              <DocumentTextIcon
                                className="h-4 w-4 mr-2 text-brand-turq"
                                aria-hidden="true"
                              />
                              {collection.enhancedRecordings.length} meetings
                            </div>
                            {isShared && (
                              <div className="flex items-center text-brand-turq">
                                <ShareIcon
                                  className="h-4 w-4 mr-2"
                                  aria-hidden="true"
                                />
                                Shared by{" "}
                                {ownerAccess.User.name ||
                                  ownerAccess.User.email}
                              </div>
                            )}
                          </div>
                          {searchQuery && collection.matches.length > 0 && (
                            <div className="mt-4 pt-4 border-t border-gray-200">
                              <p className="text-xs font-medium text-gray-500 mb-2">
                                Matched search terms:
                              </p>
                              <div className="flex flex-wrap gap-2">
                                {collection.matches.map((match, index) => (
                                  <div
                                    key={index}
                                    className="flex items-center bg-gray-100 rounded-md px-2 py-1 text-xs"
                                  >
                                    {getMatchIcon(match.type)}
                                    <span className="ml-1 text-gray-600">
                                      {match.type}
                                    </span>
                                    <span className="mx-1 text-gray-400">
                                      •
                                    </span>
                                    <span className="text-brand-turq font-medium">
                                      {match.text}
                                    </span>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
      )}
      {showManageTeamModal && (
        <ManageTeamModal
          isOpen={showManageTeamModal}
          setIsOpen={setShowManageTeamModal}
          user={user}
          setUser={setUser}
        />
      )}

      <Transition.Root show={openDeleteModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpenDeleteModal}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                    onClick={() => setOpenDeleteModal(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Delete Collection
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to delete the collection "
                        {collectionToDelete && collectionToDelete.title}"? This
                        action cannot be undone.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={deleteCollection}
                  >
                    Delete Collection
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => setOpenDeleteModal(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      {showCreateCollectionModal && (
        <CreateCollectionModal
          user={user}
          closeModal={() => setShowCreateCollectionModal(false)}
        />
      )}
    </Navbar>
  );
};

export default Collections;
