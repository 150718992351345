import React, { useState, useEffect, useRef } from "react";
import { MinusIcon, PlusIcon } from "@heroicons/react/solid";
import amplitude from "amplitude-js";

const CollectionSettingsTab = ({ collection, setCollection }) => {
  const [editTitle, setEditTitle] = useState(collection?.title || "");
  const [recurringMeetings, setRecurringMeetings] = useState([]);
  const [automationRules, setAutomationRules] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [filteredParticipants, setFilteredParticipants] = useState([]);
  const [participantInput, setParticipantInput] = useState("");
  const [showParticipantDropdown, setShowParticipantDropdown] = useState(false);

  const [domains, setDomains] = useState([]);
  const [filteredDomains, setFilteredDomains] = useState([]);
  const [domainInput, setDomainInput] = useState("");
  const [showDomainDropdown, setShowDomainDropdown] = useState(false);
  const excludedDomains = [
    "gmail.com",
    "yahoo.com",
    "hotmail.com",
    "outlook.com",
    "aol.com",
    "icloud.com",
  ];

  const [loading, setLoading] = useState(false);

  const participantInputRef = useRef(null);
  const domainInputRef = useRef(null);

  useEffect(() => {
    fetchRecurringMeetings();
    fetchAutomationRules();
    fetchRecentAndUpcomingParticipants();
  }, [collection]);

  const sortAlphabetically = (arr, key = null) => {
    return arr.sort((a, b) => {
      if (key === "name") {
        const valueA = a[key] ? a[key].toLowerCase() : a.email.toLowerCase();
        const valueB = b[key] ? b[key].toLowerCase() : b.email.toLowerCase();
        return valueA.localeCompare(valueB);
      }
      const valueA = key ? a[key].toLowerCase() : a.toLowerCase();
      const valueB = key ? b[key].toLowerCase() : b.toLowerCase();
      return valueA.localeCompare(valueB);
    });
  };
  const fetchRecurringMeetings = async () => {
    setLoading(true);
    try {
      const response = await fetch("https://backend.scribbl.co/recurring-meetings", {
        credentials: "include",
      });
      if (!response.ok) throw new Error("Failed to fetch recurring meetings");
      const data = await response.json();
      setRecurringMeetings(sortAlphabetically(data, "summary"));
    } catch (err) {
      //   setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const formatRecurringMeetingDetails = (meeting) => {
    const startTime = new Date(meeting.startTime);
    const endTime = new Date(meeting.endTime);

    const formatTime = (date) => {
      return date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    };

    const formatDay = (date) => {
      return date.toLocaleDateString([], { weekday: "long" });
    };

    const timeString = `${formatTime(startTime)} - ${formatTime(endTime)}`;
    const dayString = formatDay(startTime);

    let recurrenceString = "";
    if (meeting.recurrence && meeting.recurrence.length > 0) {
      const rrule = meeting.recurrence[0];
      if (rrule.includes("WEEKLY")) {
        recurrenceString = "Weekly";
      } else if (rrule.includes("DAILY")) {
        recurrenceString = "Daily";
      } else if (rrule.includes("MONTHLY")) {
        recurrenceString = "Monthly";
      } else if (rrule.includes("YEARLY")) {
        recurrenceString = "Yearly";
      }
    }

    return `${dayString}, ${timeString} | ${recurrenceString}`;
  };

  const fetchAutomationRules = async () => {
    try {
      const response = await fetch(
        `https://backend.scribbl.co/collections/${collection.uuid}/automation-rules`,
        {
          credentials: "include",
        }
      );
      if (!response.ok) throw new Error("Failed to fetch automation rules");
      const data = await response.json();
      setAutomationRules(sortAlphabetically(data, "value"));
    } catch (err) {
      console.error("Error fetching automation rules:", err);
    }
  };

  const fetchRecentAndUpcomingParticipants = async () => {
    try {
      const response = await fetch(
        "https://backend.scribbl.co/participants/recent-and-upcoming",
        {
          credentials: "include",
        }
      );
      if (!response.ok) throw new Error("Failed to fetch participants");
      const data = await response.json();
      const sortedParticipants = sortAlphabetically(data, "name");
      setParticipants(sortedParticipants);
      setFilteredParticipants(sortedParticipants);

      const uniqueDomains = [
        ...new Set(data.map((participant) => participant.email.split("@")[1])),
      ].filter((domain) => !excludedDomains.includes(domain));
      const sortedDomains = sortAlphabetically(uniqueDomains);
      setDomains(sortedDomains);
      setFilteredDomains(sortedDomains);
    } catch (err) {
      console.error("Error fetching participants:", err);
    }
  };

  const handleDomainInputChange = (e) => {
    const input = e.target.value;
    setDomainInput(input);
    setShowDomainDropdown(true);

    const filtered = domains.filter(
      (domain) =>
        domain.toLowerCase().includes(input.toLowerCase()) &&
        !automationRules.some(
          (rule) => rule.ruleType === "domain" && rule.value === domain
        )
    );
    setFilteredDomains(sortAlphabetically(filtered));
  };

  const handleDomainSelect = (domain) => {
    if (
      !automationRules.some(
        (rule) => rule.ruleType === "domain" && rule.value === domain
      ) &&
      !excludedDomains.includes(domain)
    ) {
      addRule("domain", domain);
      setDomainInput("");
      setShowDomainDropdown(false);
      setFilteredDomains((prevFiltered) =>
        sortAlphabetically(prevFiltered.filter((d) => d !== domain))
      );
    }
  };

  const handleDomainInputKeyDown = (e) => {
    if (e.key === "Enter" && domainInput.includes(".")) {
      e.preventDefault();
      handleDomainSelect(domainInput);
    }
  };

  const handleParticipantInputChange = (e) => {
    const input = e.target.value;
    setParticipantInput(input);
    setShowParticipantDropdown(true);

    const filtered = participants.filter(
      (participant) =>
        (participant.name.toLowerCase().includes(input.toLowerCase()) ||
          participant.email.toLowerCase().includes(input.toLowerCase())) &&
        !automationRules.some(
          (rule) =>
            rule.ruleType === "email" && rule.value === participant.email
        )
    );
    setFilteredParticipants(sortAlphabetically(filtered, "name"));
  };

  const handleParticipantSelect = (email) => {
    if (
      !automationRules.some(
        (rule) => rule.ruleType === "email" && rule.value === email
      )
    ) {
      addRule("email", email);
      setParticipantInput("");
      setShowParticipantDropdown(false);
      setFilteredParticipants((prevFiltered) =>
        sortAlphabetically(
          prevFiltered.filter((p) => p.email !== email),
          "name"
        )
      );
    }
  };

  const handleParticipantInputKeyDown = (e) => {
    if (
      e.key === "Enter" &&
      participantInput.includes("@") &&
      participantInput.includes(".")
    ) {
      e.preventDefault();
      handleParticipantSelect(participantInput);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        participantInputRef.current &&
        !participantInputRef.current.contains(event.target)
      ) {
        setShowParticipantDropdown(false);
      }
      if (
        domainInputRef.current &&
        !domainInputRef.current.contains(event.target)
      ) {
        setShowDomainDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const addRule = async (ruleType, value, name = undefined) => {
    try {
      const response = await fetch(
        `https://backend.scribbl.co/collections/${collection.uuid}/automation-rules`,
        {
          method: "POST",
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ ruleType, value, name }),
        }
      );
      if (!response.ok) throw new Error("Failed to add rule");
      const newRule = await response.json();
      setAutomationRules((prevRules) =>
        sortAlphabetically([...prevRules, newRule], "value")
      );

      amplitude
        .getInstance()
        .logEvent(`Collection dashboard: automation rules - ${ruleType}`);
    } catch (err) {
      //   setError(err.message);
    }
  };

  const removeRule = async (ruleId) => {
    try {
      const response = await fetch(
        `https://backend.scribbl.co/collections/${collection.uuid}/automation-rules/${ruleId}`,
        {
          method: "DELETE",
          credentials: "include",
        }
      );
      if (!response.ok) throw new Error("Failed to remove rule");

      const removedRule = automationRules.find((rule) => rule.ID === ruleId);

      setAutomationRules((prevRules) =>
        sortAlphabetically(
          prevRules.filter((rule) => rule.ID !== ruleId),
          "value"
        )
      );

      if (removedRule.ruleType === "email") {
        const participant = participants.find(
          (p) => p.email === removedRule.value
        );
        if (participant) {
          setFilteredParticipants((prev) =>
            sortAlphabetically([...prev, participant], "name")
          );
        }
      } else if (removedRule.ruleType === "domain") {
        setFilteredDomains((prev) =>
          sortAlphabetically([...prev, removedRule.value])
        );
      }
    } catch (err) {
      //   setError(err.message);
    }
  };

  const handleUpdateCollection = async (e) => {
    e.preventDefault();
    const response = await fetch(
      `https://backend.scribbl.co/collections/${collection.uuid}/details`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          title: editTitle,
        }),
      }
    );

    if (response.ok) {
      const data = await response.json();
      setCollection({
        ...collection,
        title: data.title,
      });
    } else {
      console.error("Failed to update collection.");
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-brand-turq"></div>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      {/* Collection Title */}
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Collection Title
          </h3>
          <form onSubmit={handleUpdateCollection} className="mt-5">
            <div className="flex items-center">
              <input
                type="text"
                value={editTitle}
                onChange={(e) => setEditTitle(e.target.value)}
                className="flex-grow mr-4 shadow-sm focus:ring-brand-turq focus:border-brand-turq block w-full sm:text-sm border-gray-300 rounded-md"
              />
              <button
                type="submit"
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-brand-turq hover:bg-brand-green-lighter1 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-turq"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* Automation Rules */}
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Automation Rules
          </h3>
          <p className="mt-2 text-sm text-gray-500">
            Configure rules to automatically add meetings to this collection
            based on various criteria.
          </p>

          {/* Recurring Calendar Events Section */}
          <div className="mt-6">
            <h4 className="text-md font-medium text-gray-700 mb-2">
              Recurring Calendar Events
            </h4>
            <p className="text-sm text-gray-500 mb-4">
              Select recurring calendar events to automatically add to this
              collection.
            </p>
            <div className="space-y-4">
              {recurringMeetings.map((meeting) => (
                <div
                  key={meeting.id}
                  className="flex items-center justify-between bg-white p-4 rounded-lg shadow mb-4"
                >
                  <div>
                    <h4 className="font-semibold">{meeting.summary}</h4>
                    <p className="text-sm text-gray-600">
                      {formatRecurringMeetingDetails(meeting)}
                    </p>
                  </div>
                  {automationRules.some(
                    (rule) =>
                      rule.ruleType === "recurring_meeting" &&
                      rule.value === meeting.id
                  ) ? (
                    <button
                      onClick={() =>
                        removeRule(
                          automationRules.find(
                            (rule) =>
                              rule.ruleType === "recurring_meeting" &&
                              rule.value === meeting.id
                          ).ID
                        )
                      }
                      className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded flex items-center"
                    >
                      <MinusIcon className="h-5 w-5 mr-2" />
                      Remove
                    </button>
                  ) : (
                    <button
                      onClick={() =>
                        addRule(
                          "recurring_meeting",
                          meeting.id,
                          meeting.summary
                        )
                      }
                      className="bg-brand-turq hover:bg-brand-green-lighter1 text-white font-bold py-2 px-4 rounded flex items-center"
                    >
                      <PlusIcon className="h-5 w-5 mr-2" />
                      Add
                    </button>
                  )}
                </div>
              ))}
            </div>
          </div>

          {/* Participant Email Addresses Section */}
          <div className="mt-6">
            <h4 className="text-md font-medium text-gray-700 mb-2">
              Participant Email Addresses
            </h4>
            <p className="text-sm text-gray-500 mb-4">
              Add email addresses to automatically include meetings with these
              participants.
            </p>
            <div className="space-y-4">
              {automationRules
                .filter((rule) => rule.ruleType === "email")
                .map((rule) => (
                  <div
                    key={rule.ID}
                    className="flex items-center justify-between bg-white p-4 rounded-lg shadow"
                  >
                    <p>{rule.value}</p>
                    <button
                      onClick={() => removeRule(rule.ID)}
                      className="text-red-500 hover:text-red-600"
                    >
                      Remove
                    </button>
                  </div>
                ))}
              <div className="relative" ref={participantInputRef}>
                <input
                  type="text"
                  value={participantInput}
                  onChange={handleParticipantInputChange}
                  onKeyDown={handleParticipantInputKeyDown}
                  onFocus={() => setShowParticipantDropdown(true)}
                  placeholder="Enter or select participant email"
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-brand-turq"
                />
                {showParticipantDropdown && (
                  <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-y-auto">
                    {filteredParticipants.map((participant) => (
                      <div
                        key={participant.email}
                        onClick={() =>
                          handleParticipantSelect(participant.email)
                        }
                        className="p-2 hover:bg-gray-100 cursor-pointer"
                      >
                        {participant.name} ({participant.email})
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Email Domains Section */}
          <div className="mt-6">
            <h4 className="text-md font-medium text-gray-700 mb-2">
              Email Domains
            </h4>
            <p className="text-sm text-gray-500 mb-4">
              Add email domains to automatically include meetings with
              participants from these domains.
            </p>
            <div className="space-y-4">
              {automationRules
                .filter((rule) => rule.ruleType === "domain")
                .map((rule) => (
                  <div
                    key={rule.ID}
                    className="flex items-center justify-between bg-white p-4 rounded-lg shadow"
                  >
                    <p>{rule.value}</p>
                    <button
                      onClick={() => removeRule(rule.ID)}
                      className="text-red-500 hover:text-red-600"
                    >
                      Remove
                    </button>
                  </div>
                ))}
              <div className="relative" ref={domainInputRef}>
                <input
                  type="text"
                  value={domainInput}
                  onChange={handleDomainInputChange}
                  onKeyDown={handleDomainInputKeyDown}
                  onFocus={() => setShowDomainDropdown(true)}
                  placeholder="Enter or select email domain"
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-brand-turq"
                />
                {showDomainDropdown && filteredDomains.length > 0 && (
                  <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-y-auto">
                    {filteredDomains.map(
                      (domain) =>
                        domain !== "gmail" && (
                          <div
                            key={domain}
                            onClick={() => handleDomainSelect(domain)}
                            className="p-2 hover:bg-gray-100 cursor-pointer"
                          >
                            {domain}
                          </div>
                        )
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollectionSettingsTab;
